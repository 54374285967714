
import Vue from 'vue'

export default Vue.extend({
  name: 'ZButton',
  props: {
    color: {
      default: '',
      type: String
    },
    iconColor: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    loadingLabel: {
      default: '',
      type: String
    },
    as: {
      default: 'button',
      type: String
    },
    justify: {
      default: 'center',
      type: String,
      validator(val: string) {
        return ['center', 'left', 'right'].includes(val)
      }
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    buttonType: {
      default: 'primary',
      type: String,
      validator(val: string) {
        return ['primary', 'secondary', 'link', 'ghost', 'danger', 'warning'].includes(val)
      }
    },
    type: {
      default: 'button',
      type: String,
      validator(val: string) {
        return ['submit', 'reset', 'button', 'link'].includes(val)
      }
    },
    size: {
      default: 'medium',
      type: String,
      validator(val: string) {
        return ['x-small', 'small', 'medium', 'large', 'xlarge'].includes(val)
      }
    },
    disabled: {
      default: false,
      type: Boolean
    },
    fullWidth: {
      default: false,
      type: Boolean
    },
    to: {
      default: '',
      type: String
    },
    icon: {
      type: String,
      default: undefined
    },
    iconSize: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      defaultClasses:
        'inline-flex items-center font-medium border-transparent rounded-sm relative justify-center focus:outline-none'
    }
  },
  methods: {
    clicked(event: Event) {
      this.$emit('click', event)
    }
  },
  computed: {
    iconColorComputed(): string {
      const colors: Record<string, string> = {
        link: 'juniper',
        ghost: 'juniper',
        primary: 'ink-400',
        secondary: 'vanilla-100',
        danger: 'ink-400',
        warning: 'ink-400'
      }

      if (this.iconColor) {
        return this.iconColor
      }

      if (this.color) {
        return this.color
      }

      return colors[this.buttonStyle]
    },

    textColor(): string {
      const colors: Record<string, string> = {
        link: 'text-juniper',
        ghost: 'text-juniper',
        primary: 'text-ink-400',
        secondary: 'text-vanilla-100',
        danger: 'text-ink-400',
        warning: 'text-ink-400'
      }

      if (this.color) {
        return `text-${this.color}`
      }

      return colors[this.buttonStyle]
    },

    iconSizeToken(): string {
      const sizes: Record<string, string> = {
        'x-small': 'small',
        small: 'small',
        medium: 'base',
        large: 'medium',
        xlarge: 'large'
      }
      return this.iconSize || sizes[this.size]
    },
    iconOnly(): boolean {
      return Boolean(this.icon && !(this.$slots['default'] || this.label))
    },
    buttonStyle(): string {
      return (this.buttonType || this.color || 'secondary') as string
    },
    isLink(): boolean {
      return Boolean(this.as === 'link' || this.type === 'link' || this.to)
    },
    justifyClass(): string {
      const classMap: Record<string, string> = {
        center: 'justify-center',
        left: 'justify-start',
        right: 'justify-end'
      }
      return classMap[this.justify] || 'justify-center'
    }
  }
})

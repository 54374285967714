import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { OwnerDetailActions } from '~/store/owner/detail'

import {
  Owner,
  IssueTypeSetting,
  IntegrationFeature,
  DeleteTeamInput,
  SyncRepositoryForOwnerInput
} from '~/types/types'

const ownerDetailStore = namespace('owner/detail')

@Component
export default class OwnerDetailMixin extends Vue {
  @ownerDetailStore.State
  owner: Owner

  @ownerDetailStore.State('loading')
  ownerStoreLoading: boolean

  @ownerDetailStore.State('error')
  ownerStoreError: Error

  @ownerDetailStore.Action(OwnerDetailActions.SET_ISSUE_TYPE_SETTING)
  setIssueTypeSettings: (args: {
    isIgnoredInCheckStatus: IssueTypeSetting['isIgnoredInCheckStatus']
    isIgnoredToDisplay: IssueTypeSetting['isIgnoredToDisplay']
    issueTypeSettingSlug: string
  }) => void

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_OWNER_ID)
  fetchOwnerID: (args: { login: string; provider: string; refetch?: boolean }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_OWNER_DETAILS)
  fetchOwnerDetails: (args: {
    login: string
    provider: string
    fetchPrimaryUser?: boolean
    refetch?: boolean
  }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_GENERAL_DATA)
  fetchGeneralData: (args: { login: string; provider: string; refetch?: boolean }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_ISSUE_TYPE_SETTINGS)
  fetchIssueTypeSettings: (args: { login: string; provider: string }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_ACCOUNT_SETUP_STATUS)
  fetchAccountSetupStatus: (args: {
    login: string
    provider: string
    refetch?: boolean
  }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_APP_CONFIG)
  fetchAppConfig: (args: { login: string; provider: string; refetch?: boolean }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.SUBMIT_ISSUE_TYPE_SETTINGS)
  submitIssueTypeSettings: (args: {
    login: string
    provider: string
    preferences: IssueTypeSetting[]
  }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.SYNC_REPOS_FOR_OWNER)
  syncReposForOwner: () => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.SYNC_SINGLE_REPO_FOR_OWNER)
  syncSingleRepoForOwner: (args: SyncRepositoryForOwnerInput) => Promise<boolean>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_OWNER_SSH_KEY)
  fetchOwnerSSHKey: (args: { login: string; provider: string }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.GENERATE_OWNER_SSH_KEY)
  generateOwnerSSHKey: (args: { ownerId: string }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.REMOVE_OWNER_SSH_KEY)
  removeOwnerSSHKey: (args: { ownerId: string }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_USAGE_DETAILS)
  fetchUsageDetails: (args: { login: string; provider: string; refetch?: boolean }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_MAX_USAGE_PERCENTAGE)
  fetchMaxUsagePercentage: (args: {
    login: string
    provider: string
    refetch?: boolean
  }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_INTEGRATIONS_FOR_FEATURE)
  fetchIntegrationForFeature: (args: {
    login: string
    provider: string
    feature: IntegrationFeature
    refetch?: boolean
  }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.FETCH_SHOULD_TIMEOUT_DATA_TRIGGER)
  fetchShouldTimeoutDataTrigger: (args: {
    login: string
    provider: string
    refetch?: boolean
  }) => Promise<void>

  @ownerDetailStore.Action(OwnerDetailActions.SET_DATA_TIMEOUT_TRIGGER)
  setDataTimeoutTrigger: (args: {
    ownerId: string
    shouldTimeoutDataTrigger: boolean
  }) => Promise<boolean>

  @ownerDetailStore.Action(OwnerDetailActions.DELETE_TEAM)
  deleteTeam: (args: DeleteTeamInput) => Promise<boolean>
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ffc5f4e = () => interopDefault(import('../pages/autofix-installation/index.vue' /* webpackChunkName: "pages/autofix-installation/index" */))
const _231bc31d = () => interopDefault(import('../pages/control-panel/index.vue' /* webpackChunkName: "pages/control-panel/index" */))
const _794effcc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _b317f894 = () => interopDefault(import('../pages/directory/index.vue' /* webpackChunkName: "pages/directory/index" */))
const _c4c89e74 = () => interopDefault(import('../pages/health.vue' /* webpackChunkName: "pages/health" */))
const _096fa3a7 = () => interopDefault(import('../pages/installation/index.vue' /* webpackChunkName: "pages/installation/index" */))
const _34625ec6 = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _73bc3405 = () => interopDefault(import('../pages/me/index.vue' /* webpackChunkName: "pages/me/index" */))
const _3543fae6 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _4f3345ab = () => interopDefault(import('../pages/settings/account.vue' /* webpackChunkName: "pages/settings/account" */))
const _31356f0c = () => interopDefault(import('../pages/settings/tokens.vue' /* webpackChunkName: "pages/settings/tokens" */))
const _9153fe20 = () => interopDefault(import('../pages/settings/workspaces.vue' /* webpackChunkName: "pages/settings/workspaces" */))
const _188f82f6 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _1bff5618 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _0bbb79da = () => interopDefault(import('../pages/team-deleted.vue' /* webpackChunkName: "pages/team-deleted" */))
const _148b90fe = () => interopDefault(import('../pages/user-deleted.vue' /* webpackChunkName: "pages/user-deleted" */))
const _4fc0fda1 = () => interopDefault(import('../pages/cli/auth.vue' /* webpackChunkName: "pages/cli/auth" */))
const _57fe0bcf = () => interopDefault(import('../pages/control-panel/license/index.vue' /* webpackChunkName: "pages/control-panel/license/index" */))
const _09c11b13 = () => interopDefault(import('../pages/control-panel/user-management/index.vue' /* webpackChunkName: "pages/control-panel/user-management/index" */))
const _059f8357 = () => interopDefault(import('../pages/ide/auth.vue' /* webpackChunkName: "pages/ide/auth" */))
const _c55c82a8 = () => interopDefault(import('../pages/installation/pending.vue' /* webpackChunkName: "pages/installation/pending" */))
const _9dd03612 = () => interopDefault(import('../pages/installation/providers.vue' /* webpackChunkName: "pages/installation/providers" */))
const _58ec3f95 = () => interopDefault(import('../pages/onboard/bitbucket.vue' /* webpackChunkName: "pages/onboard/bitbucket" */))
const _21b8dd8e = () => interopDefault(import('../pages/accounts/ads/login/index.vue' /* webpackChunkName: "pages/accounts/ads/login/index" */))
const _a7690dfc = () => interopDefault(import('../pages/accounts/bbd/login/index.vue' /* webpackChunkName: "pages/accounts/bbd/login/index" */))
const _8ef30838 = () => interopDefault(import('../pages/accounts/gitlab/login.vue' /* webpackChunkName: "pages/accounts/gitlab/login" */))
const _7e52df72 = () => interopDefault(import('../pages/accounts/gsr/projects.vue' /* webpackChunkName: "pages/accounts/gsr/projects" */))
const _2a6c80be = () => interopDefault(import('../pages/callback/integrations/jira.vue' /* webpackChunkName: "pages/callback/integrations/jira" */))
const _1489abb0 = () => interopDefault(import('../pages/callback/integrations/slack.vue' /* webpackChunkName: "pages/callback/integrations/slack" */))
const _26d084f0 = () => interopDefault(import('../pages/callback/integrations/vanta.vue' /* webpackChunkName: "pages/callback/integrations/vanta" */))
const _d409a1b4 = () => interopDefault(import('../pages/control-panel/user-management/groups/index.vue' /* webpackChunkName: "pages/control-panel/user-management/groups/index" */))
const _3432828b = () => interopDefault(import('../pages/control-panel/user-management/invites.vue' /* webpackChunkName: "pages/control-panel/user-management/invites" */))
const _7f139184 = () => interopDefault(import('../pages/team/settings/integrations/vanta.vue' /* webpackChunkName: "pages/team/settings/integrations/vanta" */))
const _2624f161 = () => interopDefault(import('../pages/accounts/ads/login/_login/index.vue' /* webpackChunkName: "pages/accounts/ads/login/_login/index" */))
const _e74efa4c = () => interopDefault(import('../pages/control-panel/user-management/groups/_groupId/index.vue' /* webpackChunkName: "pages/control-panel/user-management/groups/_groupId/index" */))
const _3f4ad63c = () => interopDefault(import('../pages/control-panel/user-management/users/_userId/index.vue' /* webpackChunkName: "pages/control-panel/user-management/users/_userId/index" */))
const _99e75c84 = () => interopDefault(import('../pages/control-panel/user-management/groups/_groupId/teams.vue' /* webpackChunkName: "pages/control-panel/user-management/groups/_groupId/teams" */))
const _070e63c6 = () => interopDefault(import('../pages/control-panel/user-management/users/_userId/teams.vue' /* webpackChunkName: "pages/control-panel/user-management/users/_userId/teams" */))
const _094081aa = () => interopDefault(import('../pages/directory/analyzers/_analyzer/index.vue' /* webpackChunkName: "pages/directory/analyzers/_analyzer/index" */))
const _adb7599a = () => interopDefault(import('../pages/directory/transformers/_transformer.vue' /* webpackChunkName: "pages/directory/transformers/_transformer" */))
const _77499953 = () => interopDefault(import('../pages/groups/invite/_inviteCode/index.vue' /* webpackChunkName: "pages/groups/invite/_inviteCode/index" */))
const _389c09f6 = () => interopDefault(import('../pages/accounts/gsr/_login/verify.vue' /* webpackChunkName: "pages/accounts/gsr/_login/verify" */))
const _4bfac112 = () => interopDefault(import('../pages/directory/analyzers/_analyzer/issues.vue' /* webpackChunkName: "pages/directory/analyzers/_analyzer/issues" */))
const _4722b215 = () => interopDefault(import('../pages/directory/analyzers/_analyzer/issues/index.vue' /* webpackChunkName: "pages/directory/analyzers/_analyzer/issues/index" */))
const _258fa727 = () => interopDefault(import('../pages/directory/analyzers/_analyzer/issues/_issue.vue' /* webpackChunkName: "pages/directory/analyzers/_analyzer/issues/_issue" */))
const _16251368 = () => interopDefault(import('../pages/installation/_provider/index.vue' /* webpackChunkName: "pages/installation/_provider/index" */))
const _5e5ec643 = () => interopDefault(import('../pages/invitation/_code/index.vue' /* webpackChunkName: "pages/invitation/_code/index" */))
const _3536c81d = () => interopDefault(import('../pages/report/_reportId.vue' /* webpackChunkName: "pages/report/_reportId" */))
const _4a6e40d6 = () => interopDefault(import('../pages/report/_reportId/code-coverage.vue' /* webpackChunkName: "pages/report/_reportId/code-coverage" */))
const _5a6070da = () => interopDefault(import('../pages/report/_reportId/code-health-trend.vue' /* webpackChunkName: "pages/report/_reportId/code-health-trend" */))
const _217d76da = () => interopDefault(import('../pages/report/_reportId/issue-distribution.vue' /* webpackChunkName: "pages/report/_reportId/issue-distribution" */))
const _2a478534 = () => interopDefault(import('../pages/report/_reportId/issues-autofixed.vue' /* webpackChunkName: "pages/report/_reportId/issues-autofixed" */))
const _bdf7ccf4 = () => interopDefault(import('../pages/report/_reportId/issues-prevented.vue' /* webpackChunkName: "pages/report/_reportId/issues-prevented" */))
const _150c6eac = () => interopDefault(import('../pages/report/_reportId/misra-c.vue' /* webpackChunkName: "pages/report/_reportId/misra-c" */))
const _5f2a3c10 = () => interopDefault(import('../pages/report/_reportId/owasp-top-10.vue' /* webpackChunkName: "pages/report/_reportId/owasp-top-10" */))
const _8aa4bb7a = () => interopDefault(import('../pages/report/_reportId/sans-top-25.vue' /* webpackChunkName: "pages/report/_reportId/sans-top-25" */))
const _008bbb58 = () => interopDefault(import('../pages/invitation/_code/confirmation.vue' /* webpackChunkName: "pages/invitation/_code/confirmation" */))
const _025a6465 = () => interopDefault(import('../pages/onboard/_provider/_owner.vue' /* webpackChunkName: "pages/onboard/_provider/_owner" */))
const _5432e582 = () => interopDefault(import('../pages/onboard/_provider/_owner/repositories.vue' /* webpackChunkName: "pages/onboard/_provider/_owner/repositories" */))
const _14fa7ada = () => interopDefault(import('../pages/onboard/_provider/_owner/_repo/config.vue' /* webpackChunkName: "pages/onboard/_provider/_owner/_repo/config" */))
const _f9c140e0 = () => interopDefault(import('../pages/onboard/_provider/_owner/_repo/preferences.vue' /* webpackChunkName: "pages/onboard/_provider/_owner/_repo/preferences" */))
const _4b71dad7 = () => interopDefault(import('../pages/onboard/_provider/_owner/_repo/running.vue' /* webpackChunkName: "pages/onboard/_provider/_owner/_repo/running" */))
const _33a88263 = () => interopDefault(import('../pages/orgs/_provider/_owner/members.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/members" */))
const _19e5f5e2 = () => interopDefault(import('../pages/orgs/_provider/_owner/members/active.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/members/active" */))
const _0abae0cf = () => interopDefault(import('../pages/orgs/_provider/_owner/members/invited.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/members/invited" */))
const _5b116fee = () => interopDefault(import('../pages/orgs/_provider/_owner/reports.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports" */))
const _170da0c5 = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/code-coverage/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/code-coverage/index" */))
const _c7bc306e = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/code-health-trend/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/code-health-trend/index" */))
const _4386191e = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/issue-distribution/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/issue-distribution/index" */))
const _1a70ab4b = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/issues-autofixed/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/issues-autofixed/index" */))
const _bfa58bc6 = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/issues-prevented/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/issues-prevented/index" */))
const _66efda99 = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/misra-c/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/misra-c/index" */))
const _45ef91b2 = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/owasp-top-10/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/owasp-top-10/index" */))
const _5e576e14 = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/public-reports/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/public-reports/index" */))
const _6169f11c = () => interopDefault(import('../pages/orgs/_provider/_owner/reports/sans-top-25/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/reports/sans-top-25/index" */))
const _48b0c5ea = () => interopDefault(import('../pages/orgs/_provider/_owner/repos.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/repos" */))
const _7eb531b2 = () => interopDefault(import('../pages/orgs/_provider/_owner/repos/_repoType.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/repos/_repoType" */))
const _4580d049 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings" */))
const _15f5309a = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/access.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/access" */))
const _2031008c = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/audit-log.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/audit-log" */))
const _822a587c = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/auto-onboard/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/auto-onboard/index" */))
const _1d3c40a2 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/general.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/general" */))
const _44e9af18 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/integrations/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/integrations/index" */))
const _6bd7bb4e = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/issue-priority.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/issue-priority" */))
const _62a8f995 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/webhooks/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/webhooks/index" */))
const _058918d8 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/integrations/jira.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/integrations/jira" */))
const _c5ffd254 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/integrations/slack.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/integrations/slack" */))
const _a1721fd4 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/integrations/vanta.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/integrations/vanta" */))
const _045b222a = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/auto-onboard/_shortcode.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/auto-onboard/_shortcode" */))
const _5e6ffbc3 = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/webhooks/_webhookId/index.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/webhooks/_webhookId/index" */))
const _5681130a = () => interopDefault(import('../pages/orgs/_provider/_owner/settings/webhooks/_webhookId/log/_logId.vue' /* webpackChunkName: "pages/orgs/_provider/_owner/settings/webhooks/_webhookId/log/_logId" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e4ed3300 = () => interopDefault(import('../pages/_provider/_owner/index.vue' /* webpackChunkName: "pages/_provider/_owner/index" */))
const _5d62347c = () => interopDefault(import('../pages/_provider/_owner/_repo/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/index" */))
const _493886e6 = () => interopDefault(import('../pages/_provider/_owner/_repo/generate-config/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/generate-config/index" */))
const _85ef0af8 = () => interopDefault(import('../pages/_provider/_owner/_repo/history.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/history" */))
const _c334580a = () => interopDefault(import('../pages/_provider/_owner/_repo/history/autofix.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/history/autofix" */))
const _42fa12a3 = () => interopDefault(import('../pages/_provider/_owner/_repo/history/runs.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/history/runs" */))
const _83682fbc = () => interopDefault(import('../pages/_provider/_owner/_repo/history/transforms.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/history/transforms" */))
const _b40ffa06 = () => interopDefault(import('../pages/_provider/_owner/_repo/issues/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/issues/index" */))
const _4189a733 = () => interopDefault(import('../pages/_provider/_owner/_repo/metrics.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/metrics" */))
const _58029f94 = () => interopDefault(import('../pages/_provider/_owner/_repo/metrics/_metric/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/metrics/_metric/index" */))
const _cb334ba2 = () => interopDefault(import('../pages/_provider/_owner/_repo/reports.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports" */))
const _4ba667ef = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/code-health-trend/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/code-health-trend/index" */))
const _1f48780b = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/issue-distribution/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/issue-distribution/index" */))
const _59e88e65 = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/issues-autofixed/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/issues-autofixed/index" */))
const _40b5c592 = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/issues-prevented/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/issues-prevented/index" */))
const _36ad9b82 = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/misra-c/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/misra-c/index" */))
const _c85c917e = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/owasp-top-10/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/owasp-top-10/index" */))
const _de7d60a4 = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/public-reports/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/public-reports/index" */))
const _c8b7c7d0 = () => interopDefault(import('../pages/_provider/_owner/_repo/reports/sans-top-25/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/reports/sans-top-25/index" */))
const _7b7402e3 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings" */))
const _1ca4aca9 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/audit-log/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/audit-log/index" */))
const _1a5e880f = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/badges/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/badges/index" */))
const _46aa6a9f = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/code-coverage/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/code-coverage/index" */))
const _5d3d9bc4 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/collaborators/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/collaborators/index" */))
const _23b578be = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/config/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/config/index" */))
const _d4083a82 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/general/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/general/index" */))
const _082f3bca = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/ignore-rules/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/ignore-rules/index" */))
const _5f50323e = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/integrations/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/integrations/index" */))
const _68a460f7 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/issue-priority/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/issue-priority/index" */))
const _3ea2e511 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/quality-gates/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/quality-gates/index" */))
const _775ace1c = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/integrations/jira.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/integrations/jira" */))
const _9132cc08 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/integrations/slack.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/integrations/slack" */))
const _6ca51988 = () => interopDefault(import('../pages/_provider/_owner/_repo/settings/integrations/vanta.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/settings/integrations/vanta" */))
const _be226206 = () => interopDefault(import('../pages/_provider/_owner/_repo/autofix/_autofix_id/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/autofix/_autofix_id/index" */))
const _51e7d7cb = () => interopDefault(import('../pages/_provider/_owner/_repo/issue/_issueId.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/issue/_issueId" */))
const _e9dc5ca0 = () => interopDefault(import('../pages/_provider/_owner/_repo/issue/_issueId/ignore-rules.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/issue/_issueId/ignore-rules" */))
const _6e027efe = () => interopDefault(import('../pages/_provider/_owner/_repo/issue/_issueId/occurrences.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/issue/_issueId/occurrences" */))
const _ef0481e4 = () => interopDefault(import('../pages/_provider/_owner/_repo/run/_runId/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/run/_runId/index" */))
const _05cd50c1 = () => interopDefault(import('../pages/_provider/_owner/_repo/run/_runId/_analyzer.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/run/_runId/_analyzer" */))
const _c01efaf8 = () => interopDefault(import('../pages/_provider/_owner/_repo/run/_runId/_analyzer/index.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/run/_runId/_analyzer/index" */))
const _640a5853 = () => interopDefault(import('../pages/_provider/_owner/_repo/run/_runId/_analyzer/_issueId.vue' /* webpackChunkName: "pages/_provider/_owner/_repo/run/_runId/_analyzer/_issueId" */))
const _dd17addc = () => interopDefault(import('../pages/auth/-index.vue' /* webpackChunkName: "pages/auth" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/autofix-installation",
    component: _1ffc5f4e,
    name: "autofix-installation"
  }, {
    path: "/control-panel",
    component: _231bc31d,
    name: "control-panel"
  }, {
    path: "/dashboard",
    component: _794effcc,
    name: "dashboard"
  }, {
    path: "/directory",
    component: _b317f894,
    name: "directory"
  }, {
    path: "/health",
    component: _c4c89e74,
    name: "health"
  }, {
    path: "/installation",
    component: _096fa3a7,
    name: "installation"
  }, {
    path: "/invitation",
    component: _34625ec6,
    name: "invitation"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/me",
    component: _73bc3405,
    name: "me"
  }, {
    path: "/settings",
    component: _3543fae6,
    name: "settings",
    children: [{
      path: "account",
      component: _4f3345ab,
      name: "settings-account"
    }, {
      path: "tokens",
      component: _31356f0c,
      name: "settings-tokens"
    }, {
      path: "workspaces",
      component: _9153fe20,
      name: "settings-workspaces"
    }]
  }, {
    path: "/signup",
    component: _188f82f6,
    name: "signup"
  }, {
    path: "/support",
    component: _1bff5618,
    name: "support"
  }, {
    path: "/team-deleted",
    component: _0bbb79da,
    name: "team-deleted"
  }, {
    path: "/user-deleted",
    component: _148b90fe,
    name: "user-deleted"
  }, {
    path: "/cli/auth",
    component: _4fc0fda1,
    name: "cli-auth"
  }, {
    path: "/control-panel/license",
    component: _57fe0bcf,
    name: "control-panel-license"
  }, {
    path: "/control-panel/user-management",
    component: _09c11b13,
    name: "control-panel-user-management"
  }, {
    path: "/ide/auth",
    component: _059f8357,
    name: "ide-auth"
  }, {
    path: "/installation/pending",
    component: _c55c82a8,
    name: "installation-pending"
  }, {
    path: "/installation/providers",
    component: _9dd03612,
    name: "installation-providers"
  }, {
    path: "/onboard/bitbucket",
    component: _58ec3f95,
    name: "onboard-bitbucket"
  }, {
    path: "/accounts/ads/login",
    component: _21b8dd8e,
    name: "accounts-ads-login"
  }, {
    path: "/accounts/bbd/login",
    component: _a7690dfc,
    name: "accounts-bbd-login"
  }, {
    path: "/accounts/gitlab/login",
    component: _8ef30838,
    name: "accounts-gitlab-login"
  }, {
    path: "/accounts/gsr/projects",
    component: _7e52df72,
    name: "accounts-gsr-projects"
  }, {
    path: "/callback/integrations/jira",
    component: _2a6c80be,
    name: "callback-integrations-jira"
  }, {
    path: "/callback/integrations/slack",
    component: _1489abb0,
    name: "callback-integrations-slack"
  }, {
    path: "/callback/integrations/vanta",
    component: _26d084f0,
    name: "callback-integrations-vanta"
  }, {
    path: "/control-panel/user-management/groups",
    component: _d409a1b4,
    name: "control-panel-user-management-groups"
  }, {
    path: "/control-panel/user-management/invites",
    component: _3432828b,
    name: "control-panel-user-management-invites"
  }, {
    path: "/team/settings/integrations/vanta",
    component: _7f139184,
    name: "team-settings-integrations-vanta"
  }, {
    path: "/accounts/ads/login/:login",
    component: _2624f161,
    name: "accounts-ads-login-login"
  }, {
    path: "/control-panel/user-management/groups/:groupId",
    component: _e74efa4c,
    name: "control-panel-user-management-groups-groupId"
  }, {
    path: "/control-panel/user-management/users/:userId",
    component: _3f4ad63c,
    name: "control-panel-user-management-users-userId"
  }, {
    path: "/control-panel/user-management/groups/:groupId?/teams",
    component: _99e75c84,
    name: "control-panel-user-management-groups-groupId-teams"
  }, {
    path: "/control-panel/user-management/users/:userId?/teams",
    component: _070e63c6,
    name: "control-panel-user-management-users-userId-teams"
  }, {
    path: "/directory/analyzers/:analyzer",
    component: _094081aa,
    name: "directory-analyzers-analyzer"
  }, {
    path: "/directory/transformers/:transformer?",
    component: _adb7599a,
    name: "directory-transformers-transformer"
  }, {
    path: "/groups/invite/:inviteCode",
    component: _77499953,
    name: "groups-invite-inviteCode"
  }, {
    path: "/accounts/gsr/:login?/verify",
    component: _389c09f6,
    name: "accounts-gsr-login-verify"
  }, {
    path: "/directory/analyzers/:analyzer?/issues",
    component: _4bfac112,
    children: [{
      path: "",
      component: _4722b215,
      name: "directory-analyzers-analyzer-issues"
    }, {
      path: ":issue",
      component: _258fa727,
      name: "directory-analyzers-analyzer-issues-issue"
    }]
  }, {
    path: "/installation/:provider",
    component: _16251368,
    name: "installation-provider"
  }, {
    path: "/invitation/:code",
    component: _5e5ec643,
    name: "invitation-code"
  }, {
    path: "/report/:reportId?",
    component: _3536c81d,
    name: "report-reportId",
    children: [{
      path: "code-coverage",
      component: _4a6e40d6,
      name: "report-reportId-code-coverage"
    }, {
      path: "code-health-trend",
      component: _5a6070da,
      name: "report-reportId-code-health-trend"
    }, {
      path: "issue-distribution",
      component: _217d76da,
      name: "report-reportId-issue-distribution"
    }, {
      path: "issues-autofixed",
      component: _2a478534,
      name: "report-reportId-issues-autofixed"
    }, {
      path: "issues-prevented",
      component: _bdf7ccf4,
      name: "report-reportId-issues-prevented"
    }, {
      path: "misra-c",
      component: _150c6eac,
      name: "report-reportId-misra-c"
    }, {
      path: "owasp-top-10",
      component: _5f2a3c10,
      name: "report-reportId-owasp-top-10"
    }, {
      path: "sans-top-25",
      component: _8aa4bb7a,
      name: "report-reportId-sans-top-25"
    }]
  }, {
    path: "/invitation/:code/confirmation",
    component: _008bbb58,
    name: "invitation-code-confirmation"
  }, {
    path: "/onboard/:provider?/:owner?",
    component: _025a6465,
    name: "onboard-provider-owner",
    children: [{
      path: "repositories",
      component: _5432e582,
      name: "onboard-provider-owner-repositories"
    }, {
      path: ":repo?/config",
      component: _14fa7ada,
      name: "onboard-provider-owner-repo-config"
    }, {
      path: ":repo?/preferences",
      component: _f9c140e0,
      name: "onboard-provider-owner-repo-preferences"
    }, {
      path: ":repo?/running",
      component: _4b71dad7,
      name: "onboard-provider-owner-repo-running"
    }]
  }, {
    path: "/orgs/:provider?/:owner?/members",
    component: _33a88263,
    name: "orgs-provider-owner-members",
    children: [{
      path: "active",
      component: _19e5f5e2,
      name: "orgs-provider-owner-members-active"
    }, {
      path: "invited",
      component: _0abae0cf,
      name: "orgs-provider-owner-members-invited"
    }]
  }, {
    path: "/orgs/:provider?/:owner?/reports",
    component: _5b116fee,
    name: "orgs-provider-owner-reports",
    children: [{
      path: "code-coverage",
      component: _170da0c5,
      name: "orgs-provider-owner-reports-code-coverage"
    }, {
      path: "code-health-trend",
      component: _c7bc306e,
      name: "orgs-provider-owner-reports-code-health-trend"
    }, {
      path: "issue-distribution",
      component: _4386191e,
      name: "orgs-provider-owner-reports-issue-distribution"
    }, {
      path: "issues-autofixed",
      component: _1a70ab4b,
      name: "orgs-provider-owner-reports-issues-autofixed"
    }, {
      path: "issues-prevented",
      component: _bfa58bc6,
      name: "orgs-provider-owner-reports-issues-prevented"
    }, {
      path: "misra-c",
      component: _66efda99,
      name: "orgs-provider-owner-reports-misra-c"
    }, {
      path: "owasp-top-10",
      component: _45ef91b2,
      name: "orgs-provider-owner-reports-owasp-top-10"
    }, {
      path: "public-reports",
      component: _5e576e14,
      name: "orgs-provider-owner-reports-public-reports"
    }, {
      path: "sans-top-25",
      component: _6169f11c,
      name: "orgs-provider-owner-reports-sans-top-25"
    }]
  }, {
    path: "/orgs/:provider?/:owner?/repos",
    component: _48b0c5ea,
    name: "orgs-provider-owner-repos",
    children: [{
      path: ":repoType?",
      component: _7eb531b2,
      name: "orgs-provider-owner-repos-repoType"
    }]
  }, {
    path: "/orgs/:provider?/:owner?/settings",
    component: _4580d049,
    name: "orgs-provider-owner-settings",
    children: [{
      path: "access",
      component: _15f5309a,
      name: "orgs-provider-owner-settings-access"
    }, {
      path: "audit-log",
      component: _2031008c,
      name: "orgs-provider-owner-settings-audit-log"
    }, {
      path: "auto-onboard",
      component: _822a587c,
      name: "orgs-provider-owner-settings-auto-onboard"
    }, {
      path: "general",
      component: _1d3c40a2,
      name: "orgs-provider-owner-settings-general"
    }, {
      path: "integrations",
      component: _44e9af18,
      name: "orgs-provider-owner-settings-integrations"
    }, {
      path: "issue-priority",
      component: _6bd7bb4e,
      name: "orgs-provider-owner-settings-issue-priority"
    }, {
      path: "webhooks",
      component: _62a8f995,
      name: "orgs-provider-owner-settings-webhooks"
    }, {
      path: "integrations/jira",
      component: _058918d8,
      name: "orgs-provider-owner-settings-integrations-jira"
    }, {
      path: "integrations/slack",
      component: _c5ffd254,
      name: "orgs-provider-owner-settings-integrations-slack"
    }, {
      path: "integrations/vanta",
      component: _a1721fd4,
      name: "orgs-provider-owner-settings-integrations-vanta"
    }, {
      path: "auto-onboard/:shortcode?",
      component: _045b222a,
      name: "orgs-provider-owner-settings-auto-onboard-shortcode"
    }, {
      path: "webhooks/:webhookId",
      component: _5e6ffbc3,
      name: "orgs-provider-owner-settings-webhooks-webhookId"
    }, {
      path: "webhooks/:webhookId/log/:logId?",
      component: _5681130a,
      name: "orgs-provider-owner-settings-webhooks-webhookId-log-logId"
    }]
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:provider/:owner",
    component: _e4ed3300,
    name: "provider-owner"
  }, {
    path: "/:provider/:owner?/:repo",
    component: _5d62347c,
    name: "provider-owner-repo"
  }, {
    path: "/:provider/:owner?/:repo/generate-config",
    component: _493886e6,
    name: "provider-owner-repo-generate-config"
  }, {
    path: "/:provider/:owner?/:repo/history",
    component: _85ef0af8,
    name: "provider-owner-repo-history",
    children: [{
      path: "autofix",
      component: _c334580a,
      name: "provider-owner-repo-history-autofix"
    }, {
      path: "runs",
      component: _42fa12a3,
      name: "provider-owner-repo-history-runs"
    }, {
      path: "transforms",
      component: _83682fbc,
      name: "provider-owner-repo-history-transforms"
    }]
  }, {
    path: "/:provider/:owner?/:repo/issues",
    component: _b40ffa06,
    name: "provider-owner-repo-issues"
  }, {
    path: "/:provider/:owner?/:repo/metrics",
    component: _4189a733,
    name: "provider-owner-repo-metrics",
    children: [{
      path: ":metric",
      component: _58029f94,
      name: "provider-owner-repo-metrics-metric"
    }]
  }, {
    path: "/:provider/:owner?/:repo/reports",
    component: _cb334ba2,
    name: "provider-owner-repo-reports",
    children: [{
      path: "code-health-trend",
      component: _4ba667ef,
      name: "provider-owner-repo-reports-code-health-trend"
    }, {
      path: "issue-distribution",
      component: _1f48780b,
      name: "provider-owner-repo-reports-issue-distribution"
    }, {
      path: "issues-autofixed",
      component: _59e88e65,
      name: "provider-owner-repo-reports-issues-autofixed"
    }, {
      path: "issues-prevented",
      component: _40b5c592,
      name: "provider-owner-repo-reports-issues-prevented"
    }, {
      path: "misra-c",
      component: _36ad9b82,
      name: "provider-owner-repo-reports-misra-c"
    }, {
      path: "owasp-top-10",
      component: _c85c917e,
      name: "provider-owner-repo-reports-owasp-top-10"
    }, {
      path: "public-reports",
      component: _de7d60a4,
      name: "provider-owner-repo-reports-public-reports"
    }, {
      path: "sans-top-25",
      component: _c8b7c7d0,
      name: "provider-owner-repo-reports-sans-top-25"
    }]
  }, {
    path: "/:provider/:owner?/:repo/settings",
    component: _7b7402e3,
    name: "provider-owner-repo-settings",
    children: [{
      path: "audit-log",
      component: _1ca4aca9,
      name: "provider-owner-repo-settings-audit-log"
    }, {
      path: "badges",
      component: _1a5e880f,
      name: "provider-owner-repo-settings-badges"
    }, {
      path: "code-coverage",
      component: _46aa6a9f,
      name: "provider-owner-repo-settings-code-coverage"
    }, {
      path: "collaborators",
      component: _5d3d9bc4,
      name: "provider-owner-repo-settings-collaborators"
    }, {
      path: "config",
      component: _23b578be,
      name: "provider-owner-repo-settings-config"
    }, {
      path: "general",
      component: _d4083a82,
      name: "provider-owner-repo-settings-general"
    }, {
      path: "ignore-rules",
      component: _082f3bca,
      name: "provider-owner-repo-settings-ignore-rules"
    }, {
      path: "integrations",
      component: _5f50323e,
      name: "provider-owner-repo-settings-integrations"
    }, {
      path: "issue-priority",
      component: _68a460f7,
      name: "provider-owner-repo-settings-issue-priority"
    }, {
      path: "quality-gates",
      component: _3ea2e511,
      name: "provider-owner-repo-settings-quality-gates"
    }, {
      path: "integrations/jira",
      component: _775ace1c,
      name: "provider-owner-repo-settings-integrations-jira"
    }, {
      path: "integrations/slack",
      component: _9132cc08,
      name: "provider-owner-repo-settings-integrations-slack"
    }, {
      path: "integrations/vanta",
      component: _6ca51988,
      name: "provider-owner-repo-settings-integrations-vanta"
    }]
  }, {
    path: "/:provider/:owner?/:repo/autofix/:autofix_id",
    component: _be226206,
    name: "provider-owner-repo-autofix-autofix_id"
  }, {
    path: "/:provider/:owner?/:repo/issue/:issueId?",
    component: _51e7d7cb,
    name: "provider-owner-repo-issue-issueId",
    children: [{
      path: "ignore-rules",
      component: _e9dc5ca0,
      name: "provider-owner-repo-issue-issueId-ignore-rules"
    }, {
      path: "occurrences",
      component: _6e027efe,
      name: "provider-owner-repo-issue-issueId-occurrences"
    }]
  }, {
    path: "/:provider/:owner?/:repo/run/:runId",
    component: _ef0481e4,
    name: "provider-owner-repo-run-runId"
  }, {
    path: "/:provider/:owner?/:repo/run/:runId?/:analyzer",
    component: _05cd50c1,
    children: [{
      path: "",
      component: _c01efaf8,
      name: "provider-owner-repo-run-runId-analyzer"
    }, {
      path: ":issueId",
      component: _640a5853,
      name: "provider-owner-repo-run-runId-analyzer-issueId"
    }]
  }, {
    path: "/accounts/github/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"GITHUB","authProvider":"github"},
    name: "github"
  }, {
    path: "/accounts/github-enterprise/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"GITHUB_ENTERPRISE","authProvider":"github-enterprise"},
    name: "github-enterprise"
  }, {
    path: "/accounts/bitbucket_oauth2/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"BITBUCKET","authProvider":"bitbucket-oauth2"},
    name: "bitbucket"
  }, {
    path: "/accounts/bitbucket_datacenter_oauth2/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"BITBUCKET_DATACENTER","authProvider":"bitbucket-datacenter-oauth2"},
    name: "bitbucket-datacenter"
  }, {
    path: "/accounts/gitlab/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"GITLAB","authProvider":"gitlab"},
    name: "gitlab"
  }, {
    path: "/accounts/google/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"GSR","authProvider":"google-oauth2"},
    name: "gsr"
  }, {
    path: "/accounts/ads/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"ADS","authProvider":"ads-oauth2"},
    name: "ads"
  }, {
    path: "/accounts/runner/apps/:appId/login/callback/bifrost",
    component: _dd17addc,
    meta: {"provider":"runner","authProvider":"runner"},
    name: "runner"
  }, {
    path: "/:provider/:owner/:repo/settings/reporting",
    redirect: {"name":"provider-owner-repo-settings-quality-gates"}
  }, {
    path: "/:provider/:owner/:repo/settings/repo-members",
    redirect: {"name":"provider-owner-repo-settings-collaborators"}
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import { render, staticRenderFns } from "./MainSidebar.vue?vue&type=template&id=2584568d&"
import script from "./MainSidebar.vue?vue&type=script&lang=ts&"
export * from "./MainSidebar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContextSwitcher: require('/app/components/Layout/Sidebar/ContextSwitcher.vue').default,ZIcon: require('/app/components/zeal/ZIcon/ZIcon.vue').default,SidebarItem: require('/app/components/Layout/Sidebar/SidebarItem.vue').default,PendingAdhocRepos: require('/app/components/Layout/Sidebar/PendingAdhocRepos.vue').default,RecentlyActiveRepo: require('/app/components/Layout/Sidebar/RecentlyActiveRepo.vue').default,DeepSourceLogo: require('/app/components/DeepSourceLogo.vue').default,ZButton: require('/app/components/zeal/ZButton/ZButton.vue').default,SupportMenu: require('/app/components/Layout/Sidebar/SupportMenu.vue').default,ExtrasMenu: require('/app/components/Layout/Sidebar/ExtrasMenu.vue').default,UserMenu: require('/app/components/Layout/Sidebar/UserMenu.vue').default,AddRepoModal: require('/app/components/AddRepo/AddRepoModal.vue').default})
